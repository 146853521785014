












































































































































































































































import { Component, Vue } from "vue-property-decorator";
import OrderApi, { CanalplusOrderRequest } from "@/services/api/order";
import { AxiosError, AxiosResponse } from "axios";
import ErrorService, { ValidationErrors } from "@/services/errors";
import Order from "@/models/order";
import Smartcard from "@/models/smartcard";
import { mdiCallMade, mdiCheck, mdiPlus, mdiTelevisionShimmer } from "@mdi/js";
import { SelectItem } from "@/types/veutify";
import { Action, Getter } from "vuex-class";
import { User } from "@/models/user";
import UserApi from "@/services/api/user";
import PriceApi from "@/services/api/prices";
import firebase, {
    addAnalyticsEvent,
    ensureAuthenticated,
} from "@/plugins/firebase";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import StatusCodes from "http-status-codes";
import CompositeAlert from "@/components/CompositeAlert.vue";
import UserProfile from "@/models/user-profile";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        CompositeAlert,
    },
    metaInfo() {
        const image = "https://nyangapay.com/img/header-1.png";
        const title =
            "Pay your CANAL+ subscription online using MTN Mobile Money, Orange Money or Bank Card";

        const description =
            "Pay your CANAL+ subscription online using MTN Mobile Money or Orange Money or Bank Card";
        return {
            title,
            meta: [
                {
                    name: "og:image",
                    content: image,
                },
                {
                    name: "twitter:image",
                    content: image,
                },
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class CanalPlusIndex extends Vue {
    formEmail: string = "";
    tickIcon: string = mdiCheck;
    formCardNumber: string = "";
    formPaymentOption: string = "";
    formPhoneNumber: string = "";
    tvIcon: string = mdiTelevisionShimmer;
    payIcon: string = mdiCallMade;
    plusIcon: string = mdiPlus;
    formReferralCode: string = "";
    formCanalPlusPackage: string = "ACCESS";
    formInputErrors: ValidationErrors = new ValidationErrors();
    isSubmitting: boolean = false;
    smartcard: Smartcard | null = null;
    canalplusPackages: Array<SelectItem> = [];

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean;
    @Getter("authToken") authToken!: string;
    @Getter("userIsAdmin") userIsAdmin!: boolean;
    @Getter("referralCode") referralCode!: string;
    @Action("setUser") setUser!: (user: User | null) => void;
    @Action("setAuthToken") setAuthToken!: (authToken: string | null) => void;

    get payUsingBankCard(): boolean {
        return this.formPaymentOption == "bank-card";
    }

    get formPhoneNumberLabel(): string {
        if (!this.formPaymentOption) {
            return "Payment Phone Number";
        }

        if (this.formPaymentOption == "mtn-mobile-money") {
            return "MTN Mobile Money Phone Number";
        }
        return "Orange Money Phone Number";
    }

    get formPhoneNumberPlaceholder(): string {
        if (!this.formPaymentOption) {
            return "Phone number e.g 6xxxxxxxx";
        }
        return `${this.paymentMethodString} number e.g 6xxxxxxxx`;
    }

    get paymentMethodString(): string {
        return this.$options.filters?.capitalize(
            this.formPaymentOption?.split("-").join(" ")
        );
    }

    get canCalculateFees(): boolean {
        return this.formCanalPlusPackage != "" && this.payUsingBankCard;
    }

    get fulfillmentAmount(): number {
        const name = this.canalplusPackages.find(
            (x) => x.value === this.formCanalPlusPackage
        )?.text;
        if (name == null) {
            return 0;
        }
        return parseInt(
            name
                .substring(name.indexOf("(") + 1, name.lastIndexOf(")"))
                .replace(",", "")
        );
    }

    get fulfillmentFees(): number {
        if (!this.payUsingBankCard) {
            return 0;
        }
        return Math.ceil(this.fulfillmentAmount * 0.03);
    }

    get totalAmount(): number {
        if (!this.payUsingBankCard) {
            return this.fulfillmentAmount;
        }
        return this.fulfillmentAmount + this.fulfillmentFees;
    }

    paySubscription() {
        addAnalyticsEvent("pay_canalplus_clicked");
        this.isSubmitting = true;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleError = (error: any) => {
            this.isSubmitting = false;
            this.handleError(error);
        };

        this.$recaptcha("submit_order")
            .then((token: string) => {
                const payload: CanalplusOrderRequest = {
                    email: this.formEmail,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    card_number: this.formCardNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    payment_method: this.formPaymentOption,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    phone_number: this.formPhoneNumber,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    canalplus_package: this.formCanalPlusPackage,
                    captcha: token,
                };
                this.ensureUserExists()
                    .then(() => {
                        this.createOrder(payload);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    }

    setProfileFromAuthUser() {
        this.formEmail = this.user?.email || "";
    }

    loadProfile() {
        if (!firebase.auth().currentUser?.uid) {
            captureSentryException(
                "cannot load profile for an unauthenticated user"
            );
            return;
        }

        UserApi.getProfile(firebase.auth().currentUser?.uid as string)
            .then((response: AxiosResponse) => {
                const userProfile = new UserProfile(response.data.data);
                this.formCanalPlusPackage =
                    userProfile.canalplus?.canalplusPackageId ??
                    this.formCanalPlusPackage;
                this.formPaymentOption = userProfile.paymentMethod ?? "";
                this.formPhoneNumber = userProfile.paymentPhoneNumber ?? "";
                this.formEmail = userProfile.email ?? this.user?.email ?? "";
                this.formCardNumber =
                    userProfile.canalplus?.cardNumber ?? this.formCardNumber;
            })
            .catch((error: AxiosError<ApiResponse>) => {
                if (error.response?.status === StatusCodes.NOT_FOUND) {
                    this.setProfileFromAuthUser();
                    return;
                }
                this.handleAxiosError(error);
            });
    }

    loadPrices() {
        PriceApi.indexCanalplus()
            .then((response: AxiosResponse) => {
                this.canalplusPackages = response.data.data;
            })
            .catch(captureSentryException);
    }

    loadReferralCode() {
        setTimeout(() => {
            this.formReferralCode = this.referralCode;
        }, 1000);
    }

    async copySmartcardName() {
        try {
            addAnalyticsEvent("copy_surname_clicked");
            await navigator.clipboard.writeText(
                this.smartcard?.customerName ?? ""
            );
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                `"${this.smartcard?.customerName}" has been copied to your clipboard`
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    mounted() {
        if (typeof this.$route.query.sc === "string") {
            this.formCardNumber = this.$route.query.sc.trim();
            addAnalyticsEvent("card_number_in_url", {
                smartcard: this.formCardNumber,
            });
        }
        this.loadPrices();
        this.loadReferralCode();
        if (this.user || this.userIsAnonymous) {
            ensureAuthenticated()
                .then(() => {
                    this.loadProfile();
                })
                .catch(this.handleError);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }

        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ensureUserExists(): Promise<any> {
        if (this.authToken) {
            return new Promise<boolean>((resolve) => resolve(true));
        }
        return firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                this.setUser(response.user);
                this.setAuthToken((await response.user?.getIdToken()) || null);
                addAnalyticsEvent("anonymous_user_created_before_order");
            });
    }

    createOrder(payload: CanalplusOrderRequest) {
        OrderApi.createCanalplus(payload)
            .then((response: AxiosResponse) => {
                const order = new Order(response.data.data);
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Your order has been created successfully"
                );

                addAnalyticsEvent("begin_checkout", {
                    currency: "XAF",
                    value: order.paymentAmount,
                    coupon: order.referralCode,
                    items: [
                        {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_id: order.itemId,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            item_name: order.itemName,
                            affiliation: "CANAL+",
                            currency: "XAF",
                        },
                    ],
                });

                this.$router.push({
                    name: this.$constants.ROUTE_NAMES.ORDERS_SHOW,
                    params: {
                        orderId: order.orderId,
                    },
                });
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
